'use strict';

const app = {
  toggleMenu: () => {
    const doc = document;
    const bodyElement = doc.querySelector('body');
    const htmlElement = doc.querySelector('html');
    const nav = doc.querySelector('.nav');
    const overlay = doc.querySelector('.overlay');

    if (nav.hasAttribute('aria-hidden')) {
      nav.removeAttribute('aria-hidden');
      nav.setAttribute('aria-live', 'assertive');
    } else {
      nav.setAttribute('aria-hidden', true);
      nav.removeAttribute('aria-live');
    }

    htmlElement.classList.toggle('is-locked');
    bodyElement.classList.toggle('is-locked');
    nav.classList.toggle('is-open');
    overlay.classList.toggle('is-active');
  }
};

document.addEventListener('click', e => {
  const elementClasses = e.target.classList;

  if (
    elementClasses.contains('nav-menu-button') ||
    elementClasses.contains('nav-menu-button-lines') ||
    elementClasses.contains('nav-menu-button-text') ||
    elementClasses.contains('nav-header-close-nav') ||
    elementClasses.contains('overlay')
  ) {
    app.toggleMenu();
  }
});

export { app };
